import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './PublicLayout.module.css';

interface PublicLayoutProps {
  children?: ReactNode;
  header?: () => ReactNode;
}

export const PublicLayout = ({ children, header }: PublicLayoutProps) => {
  return (
    <div className={styles['container']}>
      <div className={cx(styles['column'], styles['mask'])}>
        <div className={cx(styles['details'], 'h-fadein')}>{header()}</div>
      </div>

      <div className={cx(styles['column'], styles['content'])}>
        <div className={cx(styles['body'], 'h-fadein')}>{children}</div>
      </div>
    </div>
  );
};
