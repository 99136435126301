import React from 'react';
import Icon from 'components/Icon';
import { AuthenticationLayout } from 'components/Layout/AuthenticationLayout';
import { LoginForm } from 'components/Authentication/Forms';
import styles from './Login.module.css';
import { PublicLayout } from '../Layout/PublicLayout';

export function Login() {
  return (
    <PublicLayout 
      header={() => (
        <div>
          <img className='m-b' src='/images/icon-key.svg' />
          <h1 className='h-color--white h-font--uppercase'>
            login to <br />
            <span style={{ color: 'var(--pale)'}}>Crystal</span> 
            <span style={{ color: 'var(--pale)', fontWeight: 100 }}>clear</span> 
          </h1>
          <p className='h-color--white'>
            Please fill out the information to login to your account
          </p>
        </div>
      )}
    >
        <LoginForm />
    </PublicLayout>
  );
}
