import { useEffect } from 'react';
import { _GetAccessToken } from '@netfront/gelada-react-shared';
import { useRouter } from 'next/router';

export function useUnauthenticatedOnlyRoute() {
  const { push } = useRouter();

  useEffect(() => {
    Boolean(_GetAccessToken()) && push('/dashboard');
  });
}
