import { Layout } from 'components/Layout/Layout';
import Login from 'components/Login';
import withApollo from 'lib/apollo';
import { useUnauthenticatedOnlyRoute } from 'lib/hooks/useUnauthenticatedOnlyRoute';
import React from 'react';

function LoginPage() {
  useUnauthenticatedOnlyRoute();
  return (
    <Layout title="Welcome">
      <Login />
    </Layout>
  );
}

export default withApollo(LoginPage, { ssr: false });
